/**
 * Container variants
 */
@import '../common/variables';
@import '../common/mixins';

.page-container {
  width: 100%;
  height: 100%;

  > * {
    min-height: 100%;
  }
}

.container-full {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  // min-height: calc(100vh - #{$header-hg});
  > .row {
    margin: 0;

    > [class*="col-"] {
      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }
}


.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xs {
  padding: .6rem;
  width: auto;

  @media #{$min-mobile} {
    padding: 1.2rem;
  }
}

.container-lg {
  max-width: $screen-lg-min;
}

.container-md {
  max-width: $screen-md-min;
}

.container-sm {
  max-width: $screen-sm-min;
}

.container-xs {
  max-width: $screen-xs-min;
}

// remove extra spaces on mobile
@media #{$max-desktop} {
  .container-unwrap {
    padding: 0;

    > .row {
      margin: 0;

      > [class*="col-"] {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .cardbox {
      margin-bottom: 0;
    }

  }
}

.container-overlap {
  position: relative;
  padding: 2.2rem 1.2rem 4.5rem 1.2rem;

  + .container-fluid,
  + .container-lg,
  + .container-md,
  + .container-sm,
  + .container-xs {
    padding-top: 0;
    margin-top: -2.2rem;

    .push-down {
      display: block;
      height: (2.2rem + 1.2rem);
    }
  }

  &:before {
    background-color: inherit;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
}
