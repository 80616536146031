.bs-datepicker-container {
  padding: 0 !important;
}

.datepicker-theme {
  .bs-datepicker-head {
    background-color: var(--primary);
  }

  .bs-datepicker-body table {
    td span.selected,
    td.selected span,
    td span[class*="select-"]:after,
    td[class*="select-"] span:after {
      background-color: var(--primary);
    }

    td {
      &.week span {
        color: var(--primary);
      }

      &.active-week span:hover {
        cursor: pointer;
        background-color: var(--primary);
        color: #fff;
        opacity: 0.5;
        transition: 0s;
      }
    }
  }
}

.datepicker-today {
  color: var(--primary);
}
