.ng-form-select.ng-select {
  &.block {
    width: 100%;
  }

  div {
    margin-top: 0 !important;
  }

  .ng-select-container {
    margin-top: 0 !important;
    min-height: 0 !important;
    border: 1px solid #ced4da;

    .ng-value-container {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      min-height: 31.5px;
    }
  }

  &.ng-select-disabled {
    .ng-select-container {
      background-color: #f0f0f0 !important;
      opacity: 0.5;
    }
  }

  &.is-invalid {
    .ng-select-container {
      border: 1px solid #dc3545 !important;
    }
  }
}
