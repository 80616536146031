@import 'button-mixins';
@import 'tag-mixins';

@mixin create-var($name, $color) {
  --#{$name}: #{$color};
  --#{$name}-rgb: #{red($color), green($color), blue($color)};
  --#{$name}-h: #{str-replace('#{hue($color)}', 'deg', '')};
  --#{$name}-s: #{saturation($color)};
  --#{$name}-l: #{lightness($color)};
}

@mixin theme-set-buttons {
  @include primary-button;
  @include secondary-button;
  @include warning-button;
  @include danger-button;
}

@mixin theme-set-tags {
  @include primary-tag;
  @include secondary-tag;
}

@mixin theme-set-variables {
  @include set-bootstrap-variables('primary');
  @include set-bootstrap-variables('secondary');
  @include set-bootstrap-variables('gray');
  @include set-bootstrap-variables('warning');
  @include set-bootstrap-variables('danger');
}

@mixin set-bootstrap-variables($name) {
  .bg-#{$name} {
    background-color: var(--#{$name}) !important;
  }
  .border-#{$name} {
    border-color: var(--#{$name}) !important;
  }

  .alert-#{$name} {
    color: var(--#{$name}-text) !important;
    background-color: var(--#{$name}) !important;
    border-color: var(--#{$name}) !important;
  }
  .alert-outline-#{$name} {
    color: var(--#{$name}) !important;
    background-color: transparent !important;
    border-color: var(--#{$name}) !important;
  }
}
