.p-card {
  box-shadow: 0 3px 2px rgb(128 128 128 / 15%);

  .p-card-body {
    .p-card-title {
      display: flex;
      flex-shrink: 0;
    }
  }

  &.no-card-content-padding {
    .p-card-content {
      padding: 0 !important;
    }
  }
}

.p-card.dashboard-card {
  .p-card-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 1.25rem;
    font-weight: 500;
  }

  .p-card-body {
    display: flex;
    flex-direction: column;
    height: 100%;

    .p-card-content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      align-items: center;
      padding-bottom: 0;
    }
  }
}
