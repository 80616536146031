@import 'bootstrap/scss/bootstrap';

$ionicons-font-path: '~ionicons/dist/fonts';
@import 'ionicons/dist/scss/ionicons';

@import 'flag-icon-css/css/flag-icon.min.css';

@import 'material-colors/dist/colors';
@import "@ng-select/ng-select/themes/default.theme.css";
@import 'angular2-toaster/src/toaster';
@import 'ngx-ui-switch/ui-switch.component';
@import 'node_modules/ngx-bootstrap/datepicker/bs-datepicker';
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');

@import 'styles/styles.scss';
@import 'styles-v2';
