@import '../../styles/bootstrap/variables';
@import '../css-functions/darken';
@import '../mixins/button-style';

@mixin button($name) {
  $color: var(--#{$name}-text);
  $background: var(--#{$name});
  $background-rgb: var(--#{$name}-rgb);
  $background-hover: darken-css(#{$name}, 4%);
  $background-active: $background-hover;
  $border: $background;
  $border-rgb: $background-rgb;
  $border-hover: $background-hover;
  $border-active: $background-active;

  .btn-#{$name} {
    background-color: $background !important;
    color: $color !important;
    border-color: $border !important;

    &:hover {
      color: $color !important;
      background-color: $background-hover !important;
      border-color: $border-hover !important;
    }

    &:focus, .focus {
      box-shadow: 0 0 0 $input-btn-focus-width rgba($border-rgb, .5) !important;
    }

    &:disabled, .disabled {
      background-color: $background !important;
      border-color: $border !important;
    }

    &:not([disabled]):not(.disabled):active,
    &:not([disabled]):not(.disabled).active,
    .show > &.dropdown-toggle {
      color: $color !important;
      background-color: $background-active !important;
      border-color: $border-active !important;
      box-shadow: 0 0 0 $input-btn-focus-width rgba($border-rgb, .5) !important;
    }
  }

  .btn-outline-#{$name} {
    background-color: transparent !important;
    background-image: none !important;
    color: $background !important;
    border-color: $border !important;

    &:hover {
      color: $color !important;
      background-color: $background !important;
      border-color: $border !important;
    }

    &:focus, &.focus {
      box-shadow: 0 0 0 $input-btn-focus-width rgba($background-rgb, .5) !important;
    }

    &:disabled, .disabled {
      color: $background !important;
      background-color: transparent !important;
    }

    &:not([disabled]):not(.disabled):active,
    &:not([disabled]):not(.disabled).active,
    .show > &.dropdown-toggle {
      color: $color !important;
      background-color: $background !important;
      border-color: $border !important;
      box-shadow: 0 0 0 $input-btn-focus-width rgba($background-rgb, .5) !important;
    }
  }
}

@mixin p-button($name) {
  .p-button-#{$name} {
    @include p-button-style-by-name($name);
  }
}

@mixin p-togglebutton($name) {
  $color: var(--#{$name}-text);
  $background: var(--#{$name});
  $background-rgb: var(--#{$name}-rgb);
  $background-hover: darken-css(#{$name}, 4%);

  .p-togglebutton-#{$name} {
    &.p-button {
      &.p-highlight {
        background: $background;
        border-color: $background;
        color: $color;

        &:not(.p-disabled):hover {
          background: $background-hover !important;
          color: $color !important;
          border-color: $background-hover !important;
        }
      }
    }
  }
}

@mixin primary-button {
  @include button('primary');
  @include p-button('primary');
  @include p-togglebutton('primary');
}

@mixin secondary-button {
  @include button('secondary');
  @include p-button('secondary');
}

@mixin warning-button {
  @include button('warning');
  @include p-button('warning');
}

@mixin danger-button {
  @include button('danger');
  @include p-button('danger');
}
