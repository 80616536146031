/*!
 *
 * Dasha - Bootstrap Admin Template
 *
 * Version: 1.3
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */
// Open Sans font from Google
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import 'bootstrap/reset.scss';
@import 'bootstrap/custom.scss';
@import 'common/buttons-extra.scss';
@import 'common/grid-extra.scss';
@import 'common/material-colors.scss';
@import 'common/mixins.scss';
@import 'common/modal.scss';
@import 'common/containers.scss';
@import 'common/spinner.scss';
@import 'common/themes.scss';
@import 'common/typography.scss';
@import 'common/cardbox.scss';
@import 'colors/colors.scss';
@import 'node_modules/ngx-bootstrap/datepicker/bs-datepicker.scss';
@import 'custom/custom.scss';
@import '@fortawesome/fontawesome-free/css/all.min.css';
@import 'primeicons/primeicons.css';
@import 'ionicons/dist/scss/ionicons';
@import 'primeng/resources/themes/saga-blue/theme.css';
@import 'primeng/resources/primeng.min.css';
