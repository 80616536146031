@import '../../styles/bootstrap/variables';
@import '../css-functions/darken';

@mixin p-tag($name) {
  $color: var(--#{$name}-text);
  $background: var(--#{$name});
  $background-rgb: var(--#{$name}-rgb);

  .p-tag-#{$name} {
    background-color: $background;
    color: $color;

    &.p-tag-outline {
      background-color: transparent;
      color: $background;
      border: 1px solid $background;
    }
  }

  a .p-tag-#{$name} {
    cursor: pointer;

    &:hover {
      background-color: #007bff;
    }

    &.p-tag-outline {
      &:hover {
        background-color: transparent;
        border-color: #007bff;
        color: #007bff;
      }
    }
  }
}

@mixin primary-tag {
  @include p-tag('primary');
}

@mixin secondary-tag {
  @include p-tag('secondary');
}
