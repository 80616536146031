@import 'colors';
@import 'mixins';
@import 'theme-mixins';

@mixin components {
  @include theme-set-buttons;
  @include theme-set-tags;
  @include theme-set-variables;
}

.theme-light {
  $secondary: $md-blue-grey-600;

  @include theme-light;

  @include create-var('primary', $theme-tech-primary);
  @include create-var('secondary', $secondary);

  @include components;
}

.theme-dark {
  $secondary: $md-blue-grey-100;

  @include theme-dark;

  @include create-var('primary', $theme-tech-primary);
  @include create-var('secondary', $secondary);

  @include components;
}
