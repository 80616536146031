@import '../../styles/common/variables';
@import '../css-functions/index';
@import 'mixins';
@import 'material-colors/dist/colors';

@mixin theme-default {
  --primary: #000;
  --primary-rgb: 0, 0, 0;
  --primary-text: #{$md-white};

  --primary-lighter: #{lighten-css('primary', 25%)};
  --primary-darker: #{darken-css('primary', 25%)};

  --primary-gray: #{$md-blue-grey-600};

  --secondary: #000;
  --secondary-rgb: 0, 0, 0;
  --secondary-text: #{$md-white};

  --light-gray: #dee2e6;
  --mid-gray: #a2a2a2;

  @include create-var('warning', $md-orange-600);
  --warning-text: #{$md-white};

  @include create-var('danger', $md-red-600);
  --danger-text: #{$md-white};

  --header-height: 60px;

  --sidebar-background-color-1: #{$md-grey-50};
  --sidebar-background-color-2: #{$md-grey-100};
  --sidebar-background-color-3: #{$md-grey-200};
  --sidebar-background-color-3-shade: #{$md-grey-800};
  --sidebar-divider-color: #{$md-grey-800};
  --sidebar-text-color-1: #{$md-black};
  --sidebar-text-color-2: #{$md-blue-900};

  --dual-list-box-background-color: #{$md-white};

  --thumbnail-size: 4.5rem;
}

@mixin theme-light {
  @include theme-default;

  --header-color: #{$md-grey-800};
  --header-background: #{$md-white};

  --content-background: #{$md-grey-100};

  --border-radius: 3px;

  --datatable-background-color-1: #{$md-white};
  --datatable-background-color-2: #{$md-grey-50};
  --datatable-background-color-2-shade: #{$md-grey-100};
  --datatable-background-color-3: #{$md-grey-200};
  --datatable-border-color: #{$md-grey-100};
  --datatable-border-color-shade: #{$md-grey-200};
  --datatable-text-color: #{$md-grey-800};
}

@mixin theme-dark {
  @include theme-default;

  --header-color: #{$md-grey-50};
  --header-background: #{$md-grey-600};

  --content-background: #{$md-blue-grey-400};

  --border-radius: 3px;

  --datatable-background-color-1: #{$md-blue-grey-600};
  --datatable-background-color-2: #{$md-blue-grey-700};
  --datatable-background-color-2-shade: #{$md-blue-grey-800};
  --datatable-background-color-3: #{$md-blue-grey-800};
  --datatable-border-color: #{$md-blue-grey-700};
  --datatable-border-color-shade: #{$md-blue-grey-800};
  --datatable-text-color: #{$md-blue-grey-50};
}
