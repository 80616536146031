//
// Material Colors
// --------------------------------------------------

//## Red
$mdc-red-50: #FFEBEE;
$mdc-red-100: #FFCDD2;
$mdc-red-200: #EF9A9A;
$mdc-red-300: #E57373;
$mdc-red-400: #EF5350;
$mdc-red-500: #F44336;
$mdc-red-600: #E53935;
$mdc-red-700: #D32F2F;
$mdc-red-800: #C62828;
$mdc-red-900: #B71C1C;
$mdc-red-A100: #FF8A80;
$mdc-red-A200: #FF5252;
$mdc-red-A400: #FF1744;
$mdc-red-A700: #D50000;

//## Pink
$mdc-pink-50: #FCE4EC;
$mdc-pink-100: #F8BBD0;
$mdc-pink-200: #F48FB1;
$mdc-pink-300: #F06292;
$mdc-pink-400: #EC407A;
$mdc-pink-500: #E91E63;
$mdc-pink-600: #D81B60;
$mdc-pink-700: #C2185B;
$mdc-pink-800: #AD1457;
$mdc-pink-900: #880E4F;
$mdc-pink-A100: #FF80AB;
$mdc-pink-A200: #FF4081;
$mdc-pink-A400: #F50057;
$mdc-pink-A700: #C51162;

//## Purple
$mdc-purple-50: #F3E5F5;
$mdc-purple-100: #E1BEE7;
$mdc-purple-200: #CE93D8;
$mdc-purple-300: #BA68C8;
$mdc-purple-400: #AB47BC;
$mdc-purple-500: #9C27B0;
$mdc-purple-600: #8E24AA;
$mdc-purple-700: #7B1FA2;
$mdc-purple-800: #6A1B9A;
$mdc-purple-900: #4A148C;
$mdc-purple-A100: #EA80FC;
$mdc-purple-A200: #E040FB;
$mdc-purple-A400: #D500F9;
$mdc-purple-A700: #AA00FF;

//## Deep Purple
$mdc-deep-purple-50: #EDE7F6;
$mdc-deep-purple-100: #D1C4E9;
$mdc-deep-purple-200: #B39DDB;
$mdc-deep-purple-300: #9575CD;
$mdc-deep-purple-400: #7E57C2;
$mdc-deep-purple-500: #673AB7;
$mdc-deep-purple-600: #5E35B1;
$mdc-deep-purple-700: #512DA8;
$mdc-deep-purple-800: #4527A0;
$mdc-deep-purple-900: #311B92;
$mdc-deep-purple-A100: #B388FF;
$mdc-deep-purple-A200: #7C4DFF;
$mdc-deep-purple-A400: #651FFF;
$mdc-deep-purple-A700: #6200EA;

//## Indigo
$mdc-indigo-50: #E8EAF6;
$mdc-indigo-100: #C5CAE9;
$mdc-indigo-200: #9FA8DA;
$mdc-indigo-300: #7986CB;
$mdc-indigo-400: #5C6BC0;
$mdc-indigo-500: #3F51B5;
$mdc-indigo-600: #3949AB;
$mdc-indigo-700: #303F9F;
$mdc-indigo-800: #283593;
$mdc-indigo-900: #1A237E;
$mdc-indigo-A100: #8C9EFF;
$mdc-indigo-A200: #536DFE;
$mdc-indigo-A400: #3D5AFE;
$mdc-indigo-A700: #304FFE;

//## Blue
$mdc-blue-50: #E3F2FD;
$mdc-blue-100: #BBDEFB;
$mdc-blue-200: #90CAF9;
$mdc-blue-300: #64B5F6;
$mdc-blue-400: #42A5F5;
$mdc-blue-500: #2196F3;
$mdc-blue-600: #1E88E5;
$mdc-blue-700: #1976D2;
$mdc-blue-800: #1565C0;
$mdc-blue-900: #0D47A1;
$mdc-blue-A100: #82B1FF;
$mdc-blue-A200: #448AFF;
$mdc-blue-A400: #2979FF;
$mdc-blue-A700: #2962FF;

//## Light Blue
$mdc-light-blue-50: #E1F5FE;
$mdc-light-blue-100: #B3E5FC;
$mdc-light-blue-200: #81D4FA;
$mdc-light-blue-300: #4FC3F7;
$mdc-light-blue-400: #29B6F6;
$mdc-light-blue-500: #03A9F4;
$mdc-light-blue-600: #039BE5;
$mdc-light-blue-700: #0288D1;
$mdc-light-blue-800: #0277BD;
$mdc-light-blue-900: #01579B;
$mdc-light-blue-A100: #80D8FF;
$mdc-light-blue-A200: #40C4FF;
$mdc-light-blue-A400: #00B0FF;
$mdc-light-blue-A700: #0091EA;

//## Cyan
$mdc-cyan-50: #E0F7FA;
$mdc-cyan-100: #B2EBF2;
$mdc-cyan-200: #80DEEA;
$mdc-cyan-300: #4DD0E1;
$mdc-cyan-400: #26C6DA;
$mdc-cyan-500: #00BCD4;
$mdc-cyan-600: #00ACC1;
$mdc-cyan-700: #0097A7;
$mdc-cyan-800: #00838F;
$mdc-cyan-900: #006064;
$mdc-cyan-A100: #84FFFF;
$mdc-cyan-A200: #18FFFF;
$mdc-cyan-A400: #00E5FF;
$mdc-cyan-A700: #00B8D4;

//## Teal
$mdc-teal-50: #E0F2F1;
$mdc-teal-100: #B2DFDB;
$mdc-teal-200: #80CBC4;
$mdc-teal-300: #4DB6AC;
$mdc-teal-400: #26A69A;
$mdc-teal-500: #009688;
$mdc-teal-600: #00897B;
$mdc-teal-700: #00796B;
$mdc-teal-800: #00695C;
$mdc-teal-900: #004D40;
$mdc-teal-A100: #A7FFEB;
$mdc-teal-A200: #64FFDA;
$mdc-teal-A400: #1DE9B6;
$mdc-teal-A700: #00BFA5;

//## Green
$mdc-green-50: #E8F5E9;
$mdc-green-100: #C8E6C9;
$mdc-green-200: #A5D6A7;
$mdc-green-300: #81C784;
$mdc-green-400: #66BB6A;
$mdc-green-500: #4CAF50;
$mdc-green-600: #43A047;
$mdc-green-700: #388E3C;
$mdc-green-800: #2E7D32;
$mdc-green-900: #1B5E20;
$mdc-green-A100: #B9F6CA;
$mdc-green-A200: #69F0AE;
$mdc-green-A400: #00E676;
$mdc-green-A700: #00C853;

//## Light Green
$mdc-light-green-50: #F1F8E9;
$mdc-light-green-100: #DCEDC8;
$mdc-light-green-200: #C5E1A5;
$mdc-light-green-300: #AED581;
$mdc-light-green-400: #9CCC65;
$mdc-light-green-500: #8BC34A;
$mdc-light-green-600: #7CB342;
$mdc-light-green-700: #689F38;
$mdc-light-green-800: #558B2F;
$mdc-light-green-900: #33691E;
$mdc-light-green-A100: #CCFF90;
$mdc-light-green-A200: #B2FF59;
$mdc-light-green-A400: #76FF03;
$mdc-light-green-A700: #64DD17;

//## Lime
$mdc-lime-50: #F9FBE7;
$mdc-lime-100: #F0F4C3;
$mdc-lime-200: #E6EE9C;
$mdc-lime-300: #DCE775;
$mdc-lime-400: #D4E157;
$mdc-lime-500: #CDDC39;
$mdc-lime-600: #C0CA33;
$mdc-lime-700: #AFB42B;
$mdc-lime-800: #9E9D24;
$mdc-lime-900: #827717;
$mdc-lime-A100: #F4FF81;
$mdc-lime-A200: #EEFF41;
$mdc-lime-A400: #C6FF00;
$mdc-lime-A700: #AEEA00;

//## Yellow
$mdc-yellow-50: #FFFDE7;
$mdc-yellow-100: #FFF9C4;
$mdc-yellow-200: #FFF59D;
$mdc-yellow-300: #FFF176;
$mdc-yellow-400: #FFEE58;
$mdc-yellow-500: #FFEB3B;
$mdc-yellow-600: #FDD835;
$mdc-yellow-700: #FBC02D;
$mdc-yellow-800: #F9A825;
$mdc-yellow-900: #F57F17;
$mdc-yellow-A100: #FFFF8D;
$mdc-yellow-A200: #FFFF00;
$mdc-yellow-A400: #FFEA00;
$mdc-yellow-A700: #FFD600;

//## Amber
$mdc-amber-50: #FFF8E1;
$mdc-amber-100: #FFECB3;
$mdc-amber-200: #FFE082;
$mdc-amber-300: #FFD54F;
$mdc-amber-400: #FFCA28;
$mdc-amber-500: #FFC107;
$mdc-amber-600: #FFB300;
$mdc-amber-700: #FFA000;
$mdc-amber-800: #FF8F00;
$mdc-amber-900: #FF6F00;
$mdc-amber-A100: #FFE57F;
$mdc-amber-A200: #FFD740;
$mdc-amber-A400: #FFC400;
$mdc-amber-A700: #FFAB00;

//## Orange
$mdc-orange-50: #FFF3E0;
$mdc-orange-100: #FFE0B2;
$mdc-orange-200: #FFCC80;
$mdc-orange-300: #FFB74D;
$mdc-orange-400: #FFA726;
$mdc-orange-500: #FF9800;
$mdc-orange-600: #FB8C00;
$mdc-orange-700: #F57C00;
$mdc-orange-800: #EF6C00;
$mdc-orange-900: #E65100;
$mdc-orange-A100: #FFD180;
$mdc-orange-A200: #FFAB40;
$mdc-orange-A400: #FF9100;
$mdc-orange-A700: #FF6D00;

//## Deep Orange
$mdc-deep-orange-50: #FBE9E7;
$mdc-deep-orange-100: #FFCCBC;
$mdc-deep-orange-200: #FFAB91;
$mdc-deep-orange-300: #FF8A65;
$mdc-deep-orange-400: #FF7043;
$mdc-deep-orange-500: #FF5722;
$mdc-deep-orange-600: #F4511E;
$mdc-deep-orange-700: #E64A19;
$mdc-deep-orange-800: #D84315;
$mdc-deep-orange-900: #BF360C;
$mdc-deep-orange-A100: #FF9E80;
$mdc-deep-orange-A200: #FF6E40;
$mdc-deep-orange-A400: #FF3D00;
$mdc-deep-orange-A700: #DD2C00;

//## Brown
$mdc-brown-50: #EFEBE9;
$mdc-brown-100: #D7CCC8;
$mdc-brown-200: #BCAAA4;
$mdc-brown-300: #A1887F;
$mdc-brown-400: #8D6E63;
$mdc-brown-500: #795548;
$mdc-brown-600: #6D4C41;
$mdc-brown-700: #5D4037;
$mdc-brown-800: #4E342E;
$mdc-brown-900: #3E2723;

//## Grey
$mdc-grey-50: #FAFAFA;
$mdc-grey-100: #F5F5F5;
$mdc-grey-200: #EEEEEE;
$mdc-grey-300: #E0E0E0;
$mdc-grey-400: #BDBDBD;
$mdc-grey-500: #9E9E9E;
$mdc-grey-600: #757575;
$mdc-grey-700: #616161;
$mdc-grey-800: #424242;
$mdc-grey-900: #212121;

//## Blue Grey
$mdc-blue-grey-50: #ECEFF1;
$mdc-blue-grey-100: #CFD8DC;
$mdc-blue-grey-200: #B0BEC5;
$mdc-blue-grey-300: #90A4AE;
$mdc-blue-grey-400: #78909C;
$mdc-blue-grey-500: #607D8B;
$mdc-blue-grey-600: #546E7A;
$mdc-blue-grey-700: #455A64;
$mdc-blue-grey-800: #37474F;
$mdc-blue-grey-900: #263238;


//== Alpha values for grey text, icons, and dividers

//## White
$mdc-white-lighter: rgba(255, 255, 255, .12); // Dividers
$mdc-white-light: rgba(255, 255, 255, .30); // Disabled / Hint Text
$mdc-white-dark: rgba(255, 255, 255, .70); // Secondary Text
$mdc-white-darker: rgba(255, 255, 255, 1); // Text / Icons

//## Black
$mdc-black-lighter: rgba(0, 0, 0, .12); // Dividers
$mdc-black-light: rgba(0, 0, 0, .26); // Disabled / Hint Text
$mdc-black-dark: rgba(0, 0, 0, .54); // Secondary text / Icons
$mdc-black-darker: rgba(0, 0, 0, .87); // Text
