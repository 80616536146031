@import '../css-functions';

.p-component.p-selectbutton {
  &.p-selectbutton-primary {
    .p-button {
      padding: 0.625rem 1.25rem;

      .p-selectbutton-title {
        font-size: 1.05rem;
        font-weight: bold;
      }

      &.p-highlight {
        $color: var(--primary-text);
        $background: var(--primary);
        $background-hover: darken-css('primary', 4%);

        background: $background;
        color: $color;
        border-color: $background;

        &:enabled:hover {
          background: $background-hover;
          border-color: $background-hover;
          color: $color;
        }
      }
    }
  }
}
