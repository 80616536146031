@import '../css-functions';

@mixin p-button-style-by-name($name) {
  $color: var(--#{$name}-text);
  $background: var(--#{$name});
  $background-rgb: var(--#{$name}-rgb);
  $background-hover: darken-css(#{$name}, 4%);

  @include p-button-style($color, $background, $background-rgb, $background-hover);
}

@mixin p-button-style($color, $background, $background-rgb, $background-hover) {
  background: $background;
  color: $color;
  border: 1px solid $background;
  height: 2.357rem;

  &:enabled:hover {
    background: $background-hover;
    border-color: $background-hover;
    color: $color;
  }

  &.p-button-outlined {
    @include p-button-style-outlined($color, $background);
  }

  &.p-button-text {
    background-color: transparent;
    color: $background;
    border-color: transparent;

    &:enabled:hover {
      background: rgba($background-rgb, 0.04);
      color: $background;
      border-color: transparent;
    }
  }

  &.p-button-h-100 {
    height: 100% !important;
  }

  &.p-button-minimal {
    background-color: transparent;
    color: var(--mid-gray);
    border-color: transparent;
    padding: 0.5rem 0;
    width: unset;

    &:enabled:hover {
      background-color: rgba(var(--secondary-rgb), 0.04);
      color: $background;
      border-color: transparent;
    }

    &:enabled:focus {
      box-shadow: none;
    }
  }
}

@mixin p-button-style-outlined($color, $background) {
  background-color: transparent;
  color: $background;
  border: 1px solid $background;

  &:enabled:hover {
    background-color: $background;
    color: $color;
    border: 1px solid $background;
  }
}
