.p-tree {
  .p-treenode {
    &.disabled {
      opacity: 0.4;
      pointer-events: none;
    }
  }
}

.p-treeselect {
  height: calc(2.25rem + 2px);

  .p-treeselect-label {
    padding: 0.35rem 0.5rem;
  }

  &.p-disabled {
    background-color: #f0f0f0 !important;
    opacity: 0.5;
  }
}
