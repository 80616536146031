/**
 * Typography
 */
@import '../common/variables';
@import '../common/mixins';

h1 small, h2 small, h3 small, h4 small, h5 small, h6 small {
  font-weight: 400;
  line-height: 1.35;
  letter-spacing: -0.02em;
  opacity: 0.54;
  font-size: 0.6em;
  color: inherit;
}

h1 > small, h2 > small, h3 > small, h4 > small, h5 > small, h6 > small {
  margin-left: 5px;
}

small {
  color: inherit;
}

// h1 {
//     font-size: 56px;
//     font-weight: 400;
//     line-height: 1.35;
//     letter-spacing: -0.02em;
//     margin-top: 24px;
//     margin-bottom: 24px;
// }

// h2 {
//     font-size: 45px;
//     font-weight: 400;
//     line-height: 48px;
//     margin-top: 24px;
//     margin-bottom: 24px;
// }

// h3 {
//     font-size: 34px;
//     font-weight: 400;
//     line-height: 40px;
//     margin-top: 24px;
//     margin-bottom: 24px;
// }

// h4 {
//     font-size: 24px;
//     font-weight: 400;
//     line-height: 32px;
//     -moz-osx-font-smoothing: grayscale;
//     margin-top: 24px;
//     margin-bottom: 16px;
// }

// h5 {
//     font-size: 20px;
//     font-weight: 500;
//     line-height: 1;
//     letter-spacing: 0.02em;
//     margin-top: 24px;
//     margin-bottom: 16px;
// }

// h6 {
//     font-size: 16px;
//     font-weight: 400;
//     line-height: 24px;
//     letter-spacing: 0.04em;
//     margin-top: 24px;
//     margin-bottom: 16px;
// }

.blockquote {
  padding: 1rem 2rem;
  margin: 0 0 2rem;
  font-size: 1.75rem;
  border-left: .5rem solid #eee;

  &.text-right {
    border-left: 0;
    border-right: .5rem solid #eee;
  }

  p:last-child {
    margin: 0;
  }

  .small, footer, small {
    display: block;
    font-size: 80%;
    line-height: 1.42857143;
    color: #777;

    &:before {
      content: '\2014 \00A0';
    }
  }
}
