.ng-select {
  &.ng-select-multiple {
    .multi-select-container {
      background-color: #ebf5ff;
      border-radius: 2px;
      padding-top: 0.125rem;
      padding-bottom: 0.125rem;

      .multi-select-clear:hover {
        color: #D0021B;
        cursor: pointer;
      }
    }

    &.inline {
      .ng-select-container {
        .ng-value-container {
          width: 100%;
          overflow-x: hidden;
          padding-bottom: 5px;

          .multi-select-container {
            overflow-x: hidden;
            padding-top: 0 !important;
            padding-bottom: 0 !important;

            .default-text-container {
              white-space: nowrap;
              overflow-x: hidden;
              text-overflow: ellipsis;
            }
          }

          .ng-input {
            display: none;
          }
        }
      }
    }
  }

  &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  .ng-select-container {
    min-height: 39.5px !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    z-index: 0;

    .ng-clear-wrapper {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    .ng-value-container {
      .ng-value {
        background: none !important;

        .clear-container {
          @extend .btn;
          @extend .btn-outline-primary;
        }
      }
    }
  }

  &.has-error {
    .ng-select-container {
      border-color: #dc3545 !important;
    }
  }
}

.ng-dropdown-panel {
  display: flex !important;
  flex-direction: column;
  z-index: 1060 !important;

  .ng-dropdown-panel-items {
    width: 100%;
  }
}

body > .ng-dropdown-panel {
  width: auto !important;
  max-width: 200px !important;
}
