@import '../common/variables';
@import '../bootstrap/mixins';
@import '../bootstrap/variables';
@import 'ng-select';
@import 'ng-form-select';
@import 'bs-datepicker';

@each $i, $value in $spacers {
  .gap-#{$i} {
    gap: $value;
  }
}

body.swal2-shown.swal2-height-auto {
  height: 100% !important;
}

.swal2-content {
  padding: 0 !important;
}

.swal2-html-container {
  overflow: inherit !important;
  text-align: start !important;
}

.mw-0 {
  min-width: 0 !important;
}

.min-w-50 {
  min-width: 50px !important;
}

.gap-2 {
  gap:0.5rem;
}

.swal2-actions.fill-buttons {
  button.btn {
    width: 100% !important;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

.p-tooltip {
  z-index: 2000 !important;
}

.p-checkbox-label {
  margin-bottom: 0 !important;
}

.p-togglebutton {
  &.form-height {
    height: 33.5px;
  }
}

.p-overlaypanel.p-overlaypanel-limited .p-overlaypanel-content {
  max-height: 250px;
  overflow-y: auto;
}

.p-messages {
  .p-message {
    margin-top: 0 !important;
    margin-bottom: 1rem !important;

    &.p-message-success {
      color: #424242;

      .p-message-icon {
        color: #424242;
      }
    }
  }
}

body {
  .p-menu.p-menu-overlay, .p-tieredmenu.p-tieredmenu-overlay {
    z-index: 1060 !important;
    width: auto !important;
  }

  .p-tieredmenu {
    .p-submenu-list {
      min-width: 0 !important;
    }
  }

  .p-confirm-popup {
    z-index: 1060 !important;
    margin: 10px;
    max-width: 500px;

    &:before {
      margin-left: -15px;
    }

    @media (min-width: 768px) {
      margin: 10px 0 10px;
    }
  }
}

.p-togglebutton-plain {
  &.p-button {
    &.p-highlight {
      background-color: transparent !important;
      color: #2196F3 !important;
      border-color: transparent !important;

      .p-button-icon-left {
        color: #6c757d !important;
      }

      .p-button-icon-right {
        color: #6c757d !important;
      }

      &:not(.disabled) {
        &:hover {
          background: #e9ecef !important;
          border-color: #ced4da !important;
          color: #495057 !important;

          .p-button-icon-left {
            color: #6c757d !important;
          }

          .p-button-icon-right {
            color: #6c757d !important;
          }
        }
      }
    }
  }
}

.p-sidebar {
  margin: 1.5rem;
  border-radius: 5px;
  z-index: 1051 !important;
  max-width: calc(100vw - 42px);

  &.p-sidebar-right {
    height: calc(100% - 3rem) !important;
  }

  &.p-sidebar-md {
    width: 35rem !important;
  }

  &.p-sidebar-lg {
    width: 60rem !important;
  }

  &.p-sidebar-xl {
    width: 80rem !important;
  }

  .p-sidebar-content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.p-sidebar-mask {
  z-index: 1050 !important;
}

.ng-select .ng-select-container {
  background-color: #fff;
  border-radius: 0.25rem;
  border: 1px solid #ccc;
  min-height: 33.5px;
  align-items: center;
}

.h-fit-content {
  height: fit-content !important;
}

.ng-select.ng-select-single .ng-select-container {
  height: calc(2.25rem + 2px);;
}

.pointer-events-none {
  pointer-events: none !important;
}

.ng-select-disabled {
  pointer-events: none;
}

.disabled-input {
  opacity: 0.5;
  background-color: #f0f0f0;
  pointer-events: none;
}

.disabled-checkbox {
  pointer-events: none;

}

.disabled-checkbox:checked ~ .custom-control-label::before {
  background-color: #dee2e6 !important;
}

.disabled-row {
  opacity: 0.5;
  pointer-events: none;
}

.dropdown-menu {
  //display: block !important;
  transform: none;
}

.dropdown-menu .dropdown-item {
  cursor: pointer;
}

datatable-body-row {
  height: 30px;
}

.theme-default .layout-container .main-container {
  background-color: #f0f0f0;
}

.cardbox {
  .list-group {
    &-item {
      cursor: pointer;
    }
  }
}

.form-control:disabled, .form-control[readonly] {
  background-color: #f0f0f0;
  opacity: 0.5;
}

.custom-file-input:disabled, .custom-file-input[readonly] {
  ~ .custom-file-label {
    background-color: #f0f0f0;
    opacity: 0.5;
  }
}

.row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.selectable-row {
  cursor: pointer;
}

.sort-btn {
  display: none !important;
}

.sortable-btn {
  cursor: pointer;
}

.btn {
  cursor: pointer;

  &-sm {
    width: 30px;
    text-align: center;
    height: 27px;
    padding: 0.31rem 0.5rem;

    &.no-width {
      width: unset;
    }
  }
}

.datatable-icon {
  &-right, &-down {
    cursor: pointer;
  }
}

.fa {
  &.fa-edit, &.fa-trash, &.fa-plus, &.fa-minus {
    cursor: pointer;
  }
}

.sidebar-nav {
  cursor: pointer;
}

.datatable-row-center {
  .datatable-body-cell:last-child {
    padding-right: 0 !important;
    //hidden overflow
    overflow: hidden;
  }

  .datatable-header-cell:last-child {
    display: flex !important;
    justify-content: flex-end;
    align-items: flex-end;
    border-bottom: 1px solid #f0f0f0;
    padding-right: 0 !important;
  }
}

.datatable-row-detail {
  overflow-y: hidden;
}

datatable-body-cell, datatable-header-cell {
  max-height: 92px !important;
  border-right: 1px solid #f0f0f0 !important;
  border-bottom: 1px solid #f0f0f0 !important;
}

.datatable-header-cell-template-wrap div {
  margin-top: 6px;
}

.header-container > nav {
  min-height: 60px;
  box-shadow: 0px 5px 25px 0px rgb(255, 255, 255) !important;
}


//custom radiostyle

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}

[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid var(--primary) !important;
  background-color: var(--primary) !important;
  border-radius: 100%;
}

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: '';
  width: 10px;
  height: 10px;
  background: var(--primary-lighter);
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.custom-control.custom-radio {
  padding-left: 0 !important;
}

/* Customize the label (the container) */
.container-custom {
  display: block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  line-height: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-custom input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container-custom:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-custom input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-custom input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-custom .checkmark:after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 9px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.form-check.custom-form-check {
  padding-left: 0;
}

.justify-space-evenly {
  justify-content: space-evenly !important;
}

.justify-space-between {
  justify-content: space-between !important;
}

.swal2-container {

  .swal2-content {
    text-align: left !important;
  }

  .swal2-actions {
    z-index: 0 !important;
  }
}

.input-group-text {
  &:not(.as-input) {
    border: none !important;
    z-index: 4;

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &.as-input {
    background-color: white;
  }
}

popover-container.popover-full {
  min-width: 65%;
}

.popover-no-padding {
  .popover-content {
    padding: 0 !important;
  }
}

.form-control {
  &.is-invalid {
    background: none !important;
  }
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.whitespace-pre-line {
  white-space: pre-line;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre {
  white-space: pre;
}

.pl-col {
  padding-left: 8px;
}

.pr-col {
  padding-right: 8px;
}

.pt-col {
  padding-top: 8px;
}

.pb-col {
  padding-bottom: 8px;
}

.px-col {
  @extend .pl-col;
  @extend .pr-col;
}

.py-col {
  @extend .pt-col;
  @extend .pb-col;
}

.p-col {
  @extend .px-col;
  @extend .py-col;
}

@mixin fieldGroupMb0 {
  > * > .form-group:first-of-type {
    margin-bottom: 0 !important;
  }
}

.field-group-mb-0 {
  @include fieldGroupMb0;
}

.field-group-md-mb-0 {
  @media #{$min-tablet} {
    @include fieldGroupMb0;
  }
}

.min-h-100 {
  min-height: 100% !important;
}
